@import "../../shared/colors.scss";

.custom-pagination {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: flex-end;

  .page-numbers {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    width: fit-content;
    li {
      list-style-type: none;
      cursor: pointer;

      &:not(.btn) {
        width: 2.8rem;
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $dustyGrey;

        &.active {
          color: $mineShaft;
          background: $cinderella;
        }
      }

      > button {
        border: none;
        padding: 0.8rem;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 0.4rem;
        color: $white;
        background-color: $orange;
        cursor: pointer;

        &:disabled {
          color: $dustyGrey;
          background-color: $mineShaft;
        }
      }
    }
  }

  .rows-per-page {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    p {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $dustyGrey;
    }
  }
}
