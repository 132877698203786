@import "./colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir LT Std";
}

html {
  font-size: calc(100vw * 10 / 1440);
  color: $white;
}

body {
  background: $codGrey;
}
