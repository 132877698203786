.overview-card {
  max-width: 32rem;
  height: 10.4rem;
  background: #1b1b1b;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1.2rem 1.6rem;

  &-text-content {
    width: 100%;

    > h4 {
      color: #ffffff;
      font-weight: 900;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    > h5 {
      color: #9a9a9a;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
    }

    > p {
      font-size: 1.2rem;
      line-height: 1.6rem;

      &.danger {
        color: #ff3e00;
      }

      &.success {
        color: #00ba50;
      }
    }
  }

  &-label-image {
    width: 5.2rem;
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fb6320;
    border-radius: 0.8rem;

    > svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}
