.login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;

  .form-wrapper {
    width: 100%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media only screen and (min-width: 768px) {
      max-width: 45rem;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
