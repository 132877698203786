@import "../../shared/colors.scss";

.custom-button {
  padding: 0.5rem 0.8rem;
  height: 2.4rem;
  color: $dustyGrey;
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  cursor: pointer;

  &.block {
    width: 100%;
    border-radius: 0.5rem;
    height: 10rem;
    font-size: 2rem;

    @media only screen and (min-width: 768px) {
        height: 5rem;
        font-size: 1.1rem;
    }
  }

  &.colored {
    background-color: $orange;
    color: $white;
  }

  &.active,
  &:hover {
    color: $orange;
    background-color: $fairPink;
  }
}
