@import "../../shared/colors.scss";

.dashboard-page {
  padding: 4rem 1.6rem;

  .publish-announcement {
    margin-bottom: 4rem;
    display: flex;
    justify-content: flex-end;

    .button {
      width: 18rem;
      height: 4.8rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      padding: 0;
      text-transform: capitalize;
    }
  }

  .graph-wrapper {
    display: flex;
    gap: 2.4rem;
    flex-direction: column;

    @media only screen and (min-width: 580px) {
      flex-direction: row;
    }

    > div {
      @media only screen and (min-width: 580px) {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }

  .user-counts-wrapper {
    margin: 4rem 0;
  }

  .server-table-wrapper {
    .table-title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-transform: uppercase;
      color: $dustyGrey;
    }

    .server-table {
      margin: 1.6rem 0 1.8rem 0;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 1.6rem;

        > div {
          width: 32%;
          font-size: 1.2rem;
          line-height: 1.6rem;

          &:last-child {
            width: 4%;
            text-align: right;
          }
        }

        &.table-header {
          background-color: $mineShaft;

          > div {
            font-weight: 900;
            text-transform: capitalize;
          }
        }

        &.table-row {
          &:hover {
            background-color: rgba($mineShaft, 0.3);
            cursor: pointer;
          }
        }
      }
    }
  }

  .wallet-overview-wrapper {
    margin-top: 4.6rem;
    margin-bottom: 4.7rem;

    > .wallet-title {
      margin-bottom: 3rem;
      font-weight: 900;
      font-size: 1.4rem;
      line-height: 2rem;
      text-transform: uppercase;
      color: #9a9a9a;
    }

    .wallet-overview {
      display: flex;
      gap: 1.6rem;

      > div {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }

  .wallet-insights-wrapper {
    > .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h4 {
        font-weight: 900;
        font-size: 1.4rem;
        line-height: 2rem;
        text-transform: uppercase;
        color: #9a9a9a;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        > p {
          font-weight: 900;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #9a9a9a;
        }
      }
    }
  }

  & .preloader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .arrow {
    margin-right: 0.1em;
    font-size: x-small;
    cursor: pointer;
  }
  .guildType {
    white-space: nowrap;
  }
}

.dashboard-modal {
  .modal-label {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #9a9a9a;
    margin-bottom: 0.8rem;
  }

  .modal-text-input {
    display: block;
    height: 14.3rem;
    background: #202020;
    border-radius: 0.8rem;
    border: none;
    width: 100%;
    padding: 1.2rem;

    &::placeholder {
      color: #9a9a9a;
    }
  }

  .button-wrapper {
    margin-top: 2.4rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    > .custom-button {
      width: 7.2rem;
      padding: 0;
      height: 4.8rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      text-transform: capitalize;
    }
  }
}
