.custom-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(#181818, 0.8);
  display: flex;
  flex-direction: column;
  padding-top: 15rem;

  > .modal-wrapper {
    align-self: center;
    min-width: 65rem;
    min-height: 38rem;
    background: #2e2e2e;
    border-radius: 0.8rem;

    > .modal-content-wrapper {
      padding: 4rem 2.4rem;

      > .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.4rem;

        > h3 {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.4rem;
        }

        > button {
            background-color: transparent;
            border: none;
            font-size: 2.4rem;
            color: #ffff;
            cursor: pointer;
        }
      }
    }
  }
}
