.dashboard-layout {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  .logo-wrapper {
    @media only screen and (min-width: 768px) {
      width: 5.56%;
    }

    > svg {
      width: 8rem;
      height: 5.2rem;
    }
  }

  .dashboard-content-wrapper {
    @media only screen and (min-width: 768px) {
      width: 94.44%;
    }
  }
}
