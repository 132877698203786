.space-detail-page {
  .header {
    padding-top: 1.6rem;
    padding-left: 1.5rem;
    position: relative;

    > .background {
      position: absolute;
      content: "";
      width: 100%;
      height: 20.4rem;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%),
        url("../../../images/test-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    > .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 11rem;

      > button {
        display: block;
        width: 7.7rem;
        height: 2.4rem;
        background-color: #2e2e2e;
        border: none;
        border-radius: 0.4rem;
        color: #ffffff;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;
        cursor: pointer;

        > svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      .space-info {
        @media only screen and (min-width: 768px) {
          max-width: 50.1rem;
        }

        > .profile-image-wrapper {
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        > &-name {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          margin-top: 1.6rem;

          > svg {
            width: 2.4rem;
            height: 2.4rem;
          }

          > h4 {
            font-size: 2.4rem;
            line-height: 3.2rem;
          }
        }

        > &-description {
          margin: 1.6rem 0;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #9a9a9a;
        }
      }
    }
  }

  .space-detail-main {
    padding: 4.2rem 1.6rem;

    .space-detail-graph-wrapper {
      display: flex;
      gap: 2.4rem;
      margin-top: 4rem;
      flex-direction: column;

      @media only screen and (min-width: 800px) {
        flex-direction: row;
      }

      .wallet-insights-wrapper {
        @media only screen and (min-width: 800px) {
          flex-grow: 1;
          flex-basis: 0;
        }

        > .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > h4 {
            font-weight: 900;
            font-size: 1.4rem;
            line-height: 2rem;
            text-transform: uppercase;
            color: #9a9a9a;
          }

          > div {
            display: flex;
            align-items: center;
            gap: 1.6rem;

            > p {
              font-weight: 900;
              font-size: 1.2rem;
              line-height: 1.8rem;
              color: #9a9a9a;
            }
          }
        }
      }
    }
  }
}
