@import "../../shared/colors.scss";

.auth-input-wrapper {
  width: 100%;
  height: 10rem;
  border: 0.2rem solid $white;
  border-radius: 0.5rem;
  display: flex;
  background-color: transparent;

  @media only screen and (min-width: 768px) {
    height: 5rem;
  }

  input,
  select,
  textarea {
    width: 100%;
    background: transparent !important;
    border: none;
    outline: none;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.9rem;
    height: 100%;
    padding: 0 1.25rem;
    color: #ffffff !important;

    @media only screen and (min-width: 768px) {
      font-size: 1.4rem;
    }

    &::placeholder {
      opacity: 0.6;
    }
  }

  select {
    -moz-appearance: none;
    --webkit-appearance: none;
    appearance: none;

    &.no-value {
      color: $dustyGrey;
    }

    > .disabled {
      opacity: 0.3;
    }
  }

  > .toggle-button {
    display: block;
    width: 18.95%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.6rem;
    text-align: center;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    color: $orange;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
}
