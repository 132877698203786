@font-face {
  font-family: "Avenir LT Std";
  src: url("../../fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("../../fonts/AvenirLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("../../fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("../../fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
