@import "../../shared/colors.scss";

.user-counts {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 17.32rem;
  border: 1px solid $tundora;
  border-radius: 0.8rem;

  &.lg {
    padding: 1.2rem 9.17rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 0.8rem;
      text-transform: capitalize;
    }

    h4 {
      font-weight: 900;
      font-size: 4rem;
      line-height: 5.6rem;
    }
  }
}
